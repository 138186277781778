import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import NavbarTwo from "../components/App/NavbarTwo";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import AboutBanner from "../components/AboutUs/AboutUsBanner";
import AboutContent from "../components/About/AboutContent";

const About = () => {
  const {
    allContentfulMainSlider: { nodes: about },
  } = useStaticQuery(query);
  const intl = useIntl();
  const aboutImage =
    intl.locale === "en"
      ? about[0].aboutUsImage.fluid
      : about[0].aboutUsImageFr.fluid || about[0].aboutUsImage.fluid;
  const canonical = `${process.env.GATSBY_MAIN_URL}/about`;
  return (
    <Layout
      title="About Us Page"
      canonical={canonical}
      image={
        intl.locale === "en"
          ? about[0]?.aboutUsImage?.fixed.src
          : about[0]?.aboutUsImageFr?.fixed.src
      }
    >
      <NavbarTwo />
      <AboutBanner aboutImage={aboutImage} />
      <AboutContent />
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulMainSlider {
      nodes {
        aboutUsImage {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
          fixed {
            src
          }
        }
        aboutUsImageFr {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
          fixed {
            src
          }
        }
      }
    }
  }
`;

export default About;
