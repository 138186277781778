import React from "react";
import ProductCategories from "../MachineLearningAISolutions/ProductCategories";

const AboutContent = (props) => {
  return (
    <section className="blog-details-area mt-5 ">
      <div className="container">
        <div className="row product-upper-container">
          <div className="container-fluid">
            <div className=" ptb-100 product_meta">
              <h4 className="d-block">
                <section className="projects-area bg-color p-2">
                  <div className="container about-page ">
                    <div className="section-title mt-2">
                      <h2>About MedArt</h2>
                    </div>
                    <p className="text-white mb-5">
                      MedArt Technology is a leading visionary pioneer within
                      the medical aesthetics and manufacturing industry with
                      over 10 years of successfully producing, distributing, and
                      manufacturing aesthetics machinery and providing reliable
                      unprecedented services.
                    </p>
                    <p className="text-white mb-5">
                      The integrated skills and expertise of MedArt Technology’s
                      team have focused on creating new venture models to
                      broaden and underpin the group’s success by expanding in a
                      number of geographical regions.
                    </p>
                    <p className="text-white mb-5">
                      MedArt technology’s team has been paving the way towards
                      strategic growth with game-changing business projects on
                      International platforms within 12 years being active and
                      successful in different kinds of businesses as Medical
                      Aesthetic Equipment, Real Estate, Construction &
                      Development, Industrial Laser Equipment, Marketing &
                      Development.
                    </p>
                  </div>
                </section>
              </h4>
              <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                                <p>Being a market leader in the industry, providing and meeting the needs of a progressive work to overcome client needs. With over 12 years of know-how as a technology, product and marketing systems partner we offer you a comprehensive turnkey project management ranging from consulting to full marketing services.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our VISION</h3>
                                <p>The integrated skills and expertise of MedArt Technology’s team have focused on broadening and underpinning the group’s success by expanding in a number of geographical regions. To be a dynamic organization with an “out of the box” vision is being competent to come up with exclusive ideas to implement in to the market.</p>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                    <section className="projects-area bg-success mt-5 mb-5 p-5">
                      <p className="text-white">We cooperate at an inter-disciplinary way, every concept is discussed and analyzed by a brainstorming team for a maximum efficient solution.</p>
                    </section>
                    <ProductCategories/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutContent;
