import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import Image from 'gatsby-image'
import {
  useIntl,
  Link,
} from "gatsby-plugin-react-intl";

const ProductCategories = () => {
    const {allContentfulCategory : { nodes : categories }} = useStaticQuery(query);
    const intl = useIntl();
    return (
      <section className="projects-area bg-color pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <h2>{intl.formatMessage({ id: "categories" })}</h2>
          </div>

          <div className="row">
            {categories.map((category) => {
              return (
                <div className="col-lg-4 col-md-6">
                <Link to={`/${intl.locale === "en" ? "product-category" : "categorie-produit"}/${category.slug}`}>
                  <div className="single-projects-box">
                    <div className="image">
                    <Image fluid={category?.image?.fluid} alt={intl.locale === "en" ? category.name : category.name_fr } />
                    </div>
                    <div className="content">
                    <span>{intl.locale === "en" ? category.name : category.name_fr }</span>
                    </div>
                  </div>
                </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
}

const query = graphql`
  {
    allContentfulCategory {
      nodes {
        name
        name_fr
        slug
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

export default ProductCategories;