import React from "react";
import Image from "gatsby-image";



const AboutBanner = ({aboutImage}) => {
 return (
    <div className="courses-details-area">
      <div className="courses-details-image">
        <Image fluid={aboutImage} alt="Medart-about Banner" />
      </div>
    </div>
  );
};



export default AboutBanner;
